import styled from 'styled-components';
import Breakpoints from '../../styles/breakpoints';

const PageContainer = styled.div`
    min-height: 60vh;
    max-width: 1168px;
    margin: 64px auto 1rem;
    padding: 1rem;
`;

export const ProfilePageContainer = styled(PageContainer)`
    padding: 1.5rem 1rem;

    @media ${Breakpoints.md} {
        margin: 0 auto;
    }
`;

export default PageContainer;
