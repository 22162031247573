export const isClientSide = () => {
    return typeof window !== 'undefined';
};

export const isProduction = () => {
    return __CONFIG__.environment === 'production';
};

export const isDevelopment = () => {
    return __CONFIG__.environment === 'development';
};
